import PageLayout from 'components/common/PageLayout';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { useEffect } from 'react';
import NoData from 'components/Checkout/NoData';
import PendingPayment from 'components/Checkout/PendingPayment';
import CheckoutStepper from 'components/Checkout/CheckoutStepper/';
import { useTitle } from 'react-use';

const Checkout = ({
  checkout,
  checkoutRequestData,
  fetchCheckout,
  cancelPurchaseRequestData,
  cancelPurchase,
}) => {
  useTitle(`Checkout | ${process.env.REACT_APP_PAGE_TITLE}`);

  useEffect(() => {
    fetchCheckout();
  }, [fetchCheckout]);

  const getRenderComponent = (checkout) => {
    if (checkout.cart.length === 0 && !checkout.sale) return <NoData />;
    if (checkout.sale)
      return (
        <PendingPayment
          cancelPurchase={cancelPurchase}
          cancelPurchaseRequestData={cancelPurchaseRequestData}
          paymentLink={checkout.sale.payment_link}
        />
      );
    if (checkout.cart.length > 0)
      return <CheckoutStepper list={checkout.cart} />;
    return null;
  };

  return (
    <>
      <PageLayout>
        <Header />
        <main className='main-sc'>
          <div className='wrapper'>
            {checkoutRequestData.loading && (
              <div className='store-loading'>
                <i className='fas fa-spinner fa-pulse'></i>
                <span>Cargando</span>
              </div>
            )}
            {checkoutRequestData.done && getRenderComponent(checkout)}
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default Checkout;
