import { useState } from "react";
import nosotros1 from "assets/images/nosotros.jpeg";

const GennoItem = ({ item }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div key={item.name} className="genno-col">
      <h4>{item.name}</h4>
      <div className="image-slider">
        <img src={item.images} />
      </div>

      <div>
        <button onClick={handleClick} className="tech-data">
          {" "}
          Datos Técnicos
        </button>
        <div className={`tecnic-data ${open ? "open" : ""}`}>
          <ul>
            DATOS TECNICOS
            {item.datostecnicos.map((dato) => (
              <li key={dato}>{dato}</li>
            ))}
          </ul>
        </div>
      </div>

      <a className="dlm" target="_blank" href={item.url}>
        Descargá el manual
      </a>

      <a
        href="https://api.whatsapp.com/send?phone=5491163955293"
        target="_blank"
      >
        Consultar precio y stock
      </a>
    </div>
  );
};

export default GennoItem;
