const API_DOMAIN =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8888/current/servicepro2023/api"
    : "https://servicepro.com.ar/api2";

const endpoints = {
  createGuestToken: () => `${API_DOMAIN}/create-guest-token.php`,
  getTokenEntity: () => `${API_DOMAIN}/get-user-guest-token.php`,
  getCategories: () => `${API_DOMAIN}/get-categories.php`,
  getSubcategories: () => `${API_DOMAIN}/get-subcategory.php`,
  getProducts: () => `${API_DOMAIN}/get-products.php`,
  getProduct: () => `${API_DOMAIN}/get-product.php`,
  getCart: () => `${API_DOMAIN}/get-cart.php`,
  addToCart: () => `${API_DOMAIN}/add-to-cart.php`,
  changeAmountCart: () => `${API_DOMAIN}/change-amount-cart.php`,
  deleteProductCart: () => `${API_DOMAIN}/delete-product-cart.php`,
  validateCart: () => `${API_DOMAIN}/validate-cart.php`,
  purchaseMercadopago: () => `${API_DOMAIN}/purchase-mercadopago.php`,
  purchaseTransfer: () => `${API_DOMAIN}/purchase-transfer.php`,
  getFeaturedProductsHome: () => `${API_DOMAIN}/get-featured-products.php`,
  checkPurchaseStatus: () => `${API_DOMAIN}/check-purchase-status.php`,
  cancelPurchase: () => `${API_DOMAIN}/cancel-purchase.php`,
  login: () => `${API_DOMAIN}/login.php`,
  logout: () => `${API_DOMAIN}/logout.php`,
  register: () => `${API_DOMAIN}/register.php`,
  recoverPassword: () => `${API_DOMAIN}/recover-password.php`,
  getNewPassword: () => `${API_DOMAIN}/get-new-password.php`,
  fetchPurchaseStatus: () => `${API_DOMAIN}/fetch-purchase.php`,
  sendMail: () => `${API_DOMAIN}/send-mail.php`,
  search: () => `${API_DOMAIN}/search.php`,
};

export default endpoints;
