import PageLayout from "../common/PageLayout";
import Footer from "../Footer";
import Header from "../Header/Header";
import { useState } from "react";
import Swal from "sweetalert2";

const SoporteTecnico = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [fields, setFields] = useState({
    username: "",
    phone: "",
    email: "",
    city: "",
    equipment: "",
    message: "",
  });

  const handleChangeField = (field, value) => {
    setFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const submitDisabled = () => {
    if (loading) return true;

    const formFields = Object.keys(fields);
    let empty = false;

    for (let field of formFields) {
      const value = fields[field].trim();
      if (value === "") {
        empty = true;
        break;
      }
    }

    return empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");
    setLoading(true);

    try {
      /* const data = await axios.post({
        url: '',
        data: {
          username: fields.username,
          phone: fields.phone,
          email: fields.email,
          city: fields.city,
          equipment: fields.equipment,
          message: fields.message,
        },
      }); */

      await new Promise((res) => setTimeout(() => res(), 2000));

      Swal.fire({
        title: "Mensaje enviado!",
        text: "Se ha enviado correctamente!",
        icon: "success",
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Lo sentimos, se ha producido un error, intente nuevamente.");
    }
  };

  return (
    <>
      <PageLayout>
        <Header />
        <div className="soporte-tecnico-container wrapper">
          <h1>Soporte Técnico</h1>
          <form onSubmit={handleSubmit}>
            <div className="tecnic-group">
              <label>Nombre</label>
              <input
                type="text"
                value={fields.username}
                onChange={(e) => handleChangeField("username", e.target.value)}
              />
            </div>

            <div className="tecnic-group">
              <label>Teléfono</label>
              <input
                type="tel"
                value={fields.phone}
                onChange={(e) => handleChangeField("phone", e.target.value)}
              />
            </div>

            <div className="tecnic-group">
              <label>Correo</label>
              <input
                type="email"
                value={fields.email}
                onChange={(e) => handleChangeField("email", e.target.value)}
              />
            </div>

            <div className="tecnic-group">
              <label>Ciudad</label>
              <input
                type="text"
                value={fields.city}
                onChange={(e) => handleChangeField("city", e.target.value)}
              />
            </div>

            <div className="tecnic-group">
              <label>Equipos</label>
              <input
                type="text"
                value={fields.equipment}
                onChange={(e) => handleChangeField("equipment", e.target.value)}
              />
            </div>

            <div className="tecnic-group">
              <label>Mensaje</label>
              <textarea
                name="mensaje"
                cols="30"
                rows="10"
                type="text"
                value={fields.message}
                onChange={(e) => handleChangeField("message", e.target.value)}
              ></textarea>
            </div>
            {error && <p>{error}</p>}

            <button className="tecnic-button" disabled={submitDisabled()}>
              Enviar
            </button>
          </form>
        </div>
      </PageLayout>
      <Footer />
    </>
  );
};

export default SoporteTecnico;
