import PageLayout from "components/common/PageLayout";
import Header from "components/Header";
import Footer from "components/Footer";
import nosotros1 from "assets/images/nosotros.jpeg";

import { useTitle } from "react-use";

const Nosotros = () => {
  useTitle(`Nosotros | ${process.env.REACT_APP_PAGE_TITLE}`);

  return (
    <>
      <PageLayout>
        <Header />
        <main class="main-about">
          <div className="wrapper">
            <div className="cols">
              <div className="col col-1">
                <div>
                  <div className="head">
                    <h1>Nosotros</h1>
                  </div>
                  <p>
                    Somos Importadores y Distribuidores de Cercos Eléctricos de
                    la muy reconocida Marca NICE de Brasil. Nuestro compromiso
                    es brindarle a nuestros clientes la mejor solución en el
                    sector de Seguridad y este mercado se encuentra en pleno
                    desarrollo.
                  </p>
                  <p>
                    Trabajamos de forma Exclusiva la Marca NICE que
                    comercializamos a todo el país, tanto para las ventas al por
                    menor como al por mayor.
                  </p>
                  <p>
                    {" "}
                    Estos productos aparte de su nombre y ya probada nobleza,
                    cuentan con toda nuestra asistencia técnica y garantía
                    exclusiva con todos los repuestos originales en nuestras
                    oficinas y laboratorio; con atención personalizada para la
                    venta, el servicio de post venta y el servicio técnico.
                  </p>
                  <p>
                    Poseemos experiencia en Electrónica Industrial con más de 30
                    años en el mercado, lo que nos otorga una gran capacidad de
                    solución a los distintos problemas que la industria de la
                    electrónica enfrenta. Entendemos las necesidades de tiempos
                    del mercado, por ello hacemos más eficientes nuestros
                    procesos de diagnóstico y reparación a fin poder cumplir con
                    nuestros clientes.
                  </p>
                  <p>
                    Contamos con las Cetificaciones Eléctricas Homologadas de
                    acuerdo a Regulaciones Vigentes en Argentina y Mercosur.
                  </p>

                  <p className="p-spec"> SERVICEPRO es marca registrada.</p>
                </div>
              </div>
              <div className="we-pictures">
                <img src={nosotros1}></img>
              </div>
            </div>
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default Nosotros;
