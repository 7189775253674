import React, { Component, useState } from "react";
import Slider from "react-slick";
import brand1 from "../assets/images/Brands/brand1.png";
import brand2 from "../assets/images/Brands/brand2.png";
import brand3 from "../assets/images/Brands/brand3.png";
import brand4 from "../assets/images/Brands/brand4.png";
import brand5 from "../assets/images/Brands/brand5.png";
import brand6 from "../assets/images/Brands/brand6.png";
import brand7 from "../assets/images/Brands/brand3.png";
import brand8 from "../assets/images/Brands/brand4.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 7,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 1500,
      cssEase: "linear",
    };

    const brands = [
      brand1,
      brand2,
      brand3,
      brand4,
      brand5,
      brand6,
      brand7,
      brand8,
    ];

    return (
      <div className="container-brand">
        <Slider {...settings}>
          {brands.map((item, index) => (
            <div key={index}>
              <h3 className="brand">
                <img src={item} alt="" />
              </h3>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
