import { useState } from "react";
import PageLayout from "components/common/PageLayout";
import Header from "components/Header";
import Footer from "components/Footer";
import { routes } from "routing/routes";
import { Link, useHistory, useLocation } from "react-router-dom";
import { classnameDefaults, fireError } from "utils/common";
import { useEffect } from "react";
import { useTitle } from "react-use";

const initialFormState = {
  address: "",
  streets: "",
  city: "",
  province: "",
  username: "",
  email: "",
  nombre: "",
  telefono: "",
  cuit: "",
  password: "",
};

const Register = ({ registerRequestData, register, clearRegister }) => {
  useTitle(`Registro | ${process.env.REACT_APP_PAGE_TITLE}`);

  const [formData, setFormData] = useState(initialFormState);
  const location = useLocation();

  useEffect(() => {
    clearRegister();
  }, [location, clearRegister]);

  const fieldHandler = (field, e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!/^[a-z0-9]+$/.test(formData.username)) {
      fireError(
        "El usuario solo puede contener letras y números.",
        "No puede contener espacios ni otros símbolos."
      );
      return;
    }

    register(formData);
  };

  return (
    <>
      <PageLayout>
        <Header />
        <main className="htb">
          <div className="form-sec">
            <div className="wrapper">
              <div className="col-1">
                <p class="p-1">Registro Gremio</p>
                {/* <p class="p-2">
                  Completá el formulario, si sos consumidor final podés comprar
                  en el momento, si elegiste Compras comunitarias, Cooperadoras
                  o Amigxs Vegan validaremos los datos para que tengas mejores
                  precios!
                </p> */}
              </div>
              <div className="col-2">
                {registerRequestData.done && !registerRequestData.error ? (
                  <div className="form-register-after">
                    <p>¡Registro exitoso!</p>

                    <p>
                      Te hemos enviado un mail con los datos que ingresaste.
                      Revisaremos tus datos y en 24 horas hábiles tendras el
                      usuario verificado.
                    </p>

                    <p>
                      Si en 24 horas hábiles no puedes iniciar sesión,
                      contactate con nosotros para hacer una verificación
                      manual.
                    </p>

                    <Link to={routes.base.path}>Volver al inicio</Link>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <label className="label">Usuario</label>
                    <input
                      type="text"
                      name="username"
                      required
                      className="input"
                      value={formData["username"]}
                      onChange={(e) => fieldHandler("username", e)}
                    />

                    <label className="label">
                      Email <span>(debe ser válido)</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      required
                      className="input"
                      value={formData["email"]}
                      onChange={(e) => fieldHandler("email", e)}
                    />

                    <label className="label">Nombre o Razón Social</label>
                    <input
                      type="text"
                      name="nombre"
                      required
                      className="input"
                      value={formData["nombre"]}
                      onChange={(e) => fieldHandler("nombre", e)}
                    />

                    <label className="label">Teléfono</label>
                    <input
                      type="tel"
                      name="telefono"
                      required
                      className="input"
                      value={formData["telefono"]}
                      onChange={(e) => fieldHandler("telefono", e)}
                    />

                    <label className="label">Dirección</label>
                    <input
                      type="text"
                      name="address"
                      required
                      className="input"
                      value={formData["address"]}
                      onChange={(e) => fieldHandler("address", e)}
                    />

                    <label className="label">Entre calles</label>
                    <input
                      type="text"
                      name="streets"
                      required
                      className="input"
                      value={formData["streets"]}
                      onChange={(e) => fieldHandler("streets", e)}
                    />

                    <label className="label">Ciudad</label>
                    <input
                      type="text"
                      name="city"
                      required
                      className="input"
                      value={formData["city"]}
                      onChange={(e) => fieldHandler("city", e)}
                    />

                    <label className="label">Provincia</label>
                    <input
                      type="text"
                      name="province"
                      required
                      className="input"
                      value={formData["province"]}
                      onChange={(e) => fieldHandler("province", e)}
                    />

                    <label className="label">
                      C.U.I.T. <span>(sólo numeros)</span>
                    </label>
                    <input
                      type="number"
                      name="cuit"
                      required
                      className="input"
                      value={formData["cuit"]}
                      onChange={(e) => fieldHandler("cuit", e)}
                    />

                    <label className="label">Contraseña</label>
                    <input
                      type="password"
                      name="password"
                      required
                      className="input"
                      value={formData["password"]}
                      onChange={(e) => fieldHandler("password", e)}
                    />

                    <button
                      disabled={registerRequestData.loading}
                      className={`button ${classnameDefaults([
                        registerRequestData.loading,
                        "disabled",
                      ])}`}
                    >
                      {!registerRequestData.loading ? (
                        "Registrarse"
                      ) : (
                        <>
                          <span>Registrando</span>{" "}
                          <i className="fas fa-spinner fa-pulse"></i>
                        </>
                      )}
                    </button>

                    <p className="register-label">
                      Si ya estás registrado, hace{" "}
                      <Link to={routes.login.path}>click acá</Link> para iniciar
                      sesión.
                    </p>
                  </form>
                )}
              </div>
            </div>
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default Register;
