import Footer from "./Footer";
import Header from "./Header";
import PageLayout from "./common/PageLayout";

import pdf1 from "assets/images/impacto.pdf";
import pdf2 from "assets/images/rev-control.pdf";
import pdf3 from "assets/images/premium.pdf";
import pdf4 from "assets/images/discador-universal.pdf";
import pdf5 from "assets/images/discador-sim.pdf";
import GennoItem from "./GennoItem";
import imagen1 from "assets/images/1.jpeg";
import imagen2 from "assets/images/2.jpeg";
import imagen3 from "assets/images/3.jpeg";
import imagen4 from "assets/images/4.jpeg";

const Genno = () => {
  const products = [
    {
      name: "Impacto",
      images: imagen1,
      datostecnicos: [
        "Tensión de choque: 8.000V / 10.000V / 12.000V",
        "Energía emitida: 0,6 joules",
        "Tamaño máximo de la cerca: 3.100 metros lineales",
        "Forma de accionamiento: por botón en el panel",
        "Índice de protección IPX4",
        "Salida de LED",
        "Entrada para dispositivo externo (teclado, receptor, botoera, etc)",
        "Ajuste de los pulsos fallidos",
        "Shutdown de sirena programable",
        "Shutdown de choque programable",
        "Tiempo de sirena ajustable",
        "Beep de sirena programable",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: pdf1,
    },
    {
      name: "Revolution Control",
      images: imagen2,
      datostecnicos: [
        "Tensión de choque: 8.000V / 10.000v / 12.000V",
        "Energía emitida: 0,6 joules",
        "Tamaño máximo de la cerca: 3.100 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Función pánico por control",
        "Índice de protección IPX4",
        "Salida de LED",
        "Entrada para dispositivo externo (teclado, receptor, bota, etc)",
        "Ajuste de los pulsos fallidos",
        "Shutdown de sirena programable",
        "Shutdown de choque programable",
        "Tiempo de sirena ajustable",
        "Beep de sirena programable",
        "Permite integrar el control vía smartphone cuando interconectado con alarma en la línea de Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: pdf2,
    },
    {
      name: "Shock Premium",
      images: imagen3,
      datostecnicos: [
        "Tensión de choque: 18.000V / 20.000V / 22.000V",
        "Energía emitida: 2,5 joules",
        "Tamaño máximo de la cerca: 5.000 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Función pánico por control",
        "Zonas configurables (instantánea, inteligente o temporizado)",
        "Índice de protección IPX4",
        "Salida de LED",
        "Entrada para dispositivo externo (teclado, receptor, botoera, etc)",
        "Ajuste de los pulsos fallidos",
        "Shutdown de sirena programable",
        "Shutdown de choque programable",
        "Tiempo de sirena ajustable",
        "Beep de sirena programable",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: pdf3,
    },
    {
      name: "DISCADORA UNIVERSAL DPTF-05PT",
      images: imagen4,
      datostecnicos: [
        "Tensión de choque: 18.000V / 20.000V / 22.000V",
        "Energía emitida: 2,5 joules",
        "Tamaño máximo de la cerca: 5.000 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: pdf4,
    },
    {
      name: "DISCADOR SIM-1010GSM",
      images: imagen4,
      datostecnicos: [
        "Tensión de choque: 18.000V / 20.000V / 22.000V",
        "Energía emitida: 2,5 joules",
        "Tamaño máximo de la cerca: 5.000 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: pdf5,
    },
    {
      name: "SIRENA de exterior e interior",
      images: imagen4,
      datostecnicos: [
        "Dos tonalidades: Negra y/o blanca",
        "Energía emitida: 2,5 joules",
        "Tamaño máximo de la cerca: 5.000 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: "pdf/premium.pdf",
    },
    {
      name: "C.REMOTO TXTECH 433 - PRETO c/pila",
      images: imagen4,
      datostecnicos: [
        "Dos tonalidades: Negra y/o blanca",
        "Energía emitida: 2,5 joules",
        "Tamaño máximo de la cerca: 5.000 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: "pdf/premium.pdf",
    },
    {
      name: "REPOS CHIP Grabado SEIMPV-5 Impacto",
      images: imagen4,
      datostecnicos: [
        "Dos tonalidades: Negra y/o blanca",
        "Energía emitida: 2,5 joules",
        "Tamaño máximo de la cerca: 5.000 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: "pdf/premium.pdf",
    },
    {
      name: "REPOS BOBINA RESINADA ABNT - 7",
      images: imagen4,
      datostecnicos: [
        "Dos tonalidades: Negra y/o blanca",
        "Energía emitida: 2,5 joules",
        "Tamaño máximo de la cerca: 5.000 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: "pdf/premium.pdf",
    },

    {
      name: "REPOS CHIP Grabado SERCSPV-5 Revol / Premium",
      images: imagen4,
      datostecnicos: [
        "Dos tonalidades: Negra y/o blanca",
        "Energía emitida: 2,5 joules",
        "Tamaño máximo de la cerca: 5.000 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: "pdf/premium.pdf",
    },
    {
      name: "REPOS CHIP Grabado SERCSPV-5 Revol / Premium",
      images: imagen4,
      datostecnicos: [
        "Dos tonalidades: Negra y/o blanca",
        "Energía emitida: 2,5 joules",
        "Tamaño máximo de la cerca: 5.000 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: "pdf/premium.pdf",
    },

    {
      name: "REPOS TRAFO RED 110/220 50HZ COM P FUS - 6",
      images: imagen4,
      datostecnicos: [
        "Dos tonalidades: Negra y/o blanca",
        "Energía emitida: 2,5 joules",
        "Tamaño máximo de la cerca: 5.000 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: "pdf/premium.pdf",
    },
    {
      name: "Batería de Gel 12V 7AH recargable alto rendimiento",
      images: imagen4,
      datostecnicos: [
        "Dos tonalidades: Negra y/o blanca",
        "Energía emitida: 2,5 joules",
        "Tamaño máximo de la cerca: 5.000 metros lineales",
        "Forma de accionamiento: control remoto",
        "2 sectores mixtos e independientes",
        "Permite integrar el control vía smatphone cuando interconectado con alarma de la línea Genno Cloud con GPRS e Ethernet/Wifi",
      ],
      url: "pdf/premium.pdf",
    },
  ];
  return (
    <div>
      <PageLayout>
        <Header />
        <main>
          <div className="wrapper">
            <section className="contacto clearfix">
              <h1 className="title genno-title">
                Electrificadores Genno
                <br />
                Especial Instalador/Gremio
              </h1>

              <div className="featured">
                <p>
                  <i className="fa fa-check-circle"></i>Garantía extendida de{" "}
                  <b>6 meses</b> y reparaciones <b>con repuestos originales</b>{" "}
                  en nuestras oficinas.
                </p>
              </div>

              <div className="genno-cols">
                {products.map((item) => (
                  <GennoItem item={item} key={item.name} />
                ))}
              </div>
            </section>

            <h2 className="genno-fact">
              Hacemos Facturas "A" - Pago contado efectivo/transferencia
              <br />
              Consultá por controles remotos Genno y otros insumos
            </h2>
          </div>
        </main>
        <Footer />
      </PageLayout>
    </div>
  );
};

export default Genno;
