import LogoImage from "assets/images/logo.jpeg";
import { useState } from "react";
import { classnameDefaults } from "utils/common";
import { Link } from "react-router-dom";
import { routes } from "routing/routes";
import Cambios from "components/Footer/DialogContent/Cambios";
import ComoPagar from "components/Footer/DialogContent/ComoPagar";
import DondeEstamos from "components/Footer/DialogContent/DondeEstamos";
import MetodosEnvio from "components/Footer/DialogContent/MetodosEnvio";
import PoliticasDevolucion from "components/Footer/DialogContent/PoliticasDevolucion";
import ComprasComunitarias from "./DialogContent/ComprasComunitarias";
import Logodev from "assets/images/logo-dev.png";
import QR from "assets/images/qr-big.png";
import Provedores from "./DialogContent/Proveedores";
import CapacidadesDiferentes from "./DialogContent/CapacidadesDiferentes";
import PuntoRetiro from "./DialogContent/PuntoRetiro";
import wplogo from "assets/images/whatsapp.png";
import ScrollToTop from "../ScrollToTop";

const POPUPS = {
  descargas: "descargas",
  formasPago: "formasPago",
};

const Footer = () => {
  const [whatsappPopupOpen, setWhatsappPopupOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const handleDialogOpen = (component) => {
    setDialogContent(component);
    setDialogOpen(true);
  };

  return (
    <>
      <ScrollToTop />
      <div className={`qr_overlay ${classnameDefaults([qrOpen, "active"])}`}>
        <button onClick={() => setQrOpen(false)}>
          <i className="fas fa-times"></i>
        </button>
        <img src={QR} alt="Escanea y pagá" />
      </div>
      <div
        className={`pop-overlay ${classnameDefaults([dialogOpen, "active"])}`}
      >
        <i className="fas fa-times" onClick={() => setDialogOpen(false)}></i>
        <div className="pop-box">{dialogContent}</div>
      </div>
      <footer>
        <div className="wrapper footer-wrp">
          <div className="cols">
            <div className="col col-1">
              <a href=".">
                <img src={LogoImage} alt="Logotipo Service Pro" />
              </a>
            </div>

            <div className="col col-2">
              <div className="wp-contain">
                <p className="mail-cont">ventas@servicepro.com.ar</p>
              </div>
            </div>
            <div className="col col-2">
              <div className="wp-contain">
                <p className="contacto-telefono">
                  <i className="fas fa-phone"></i>
                  <a className="tel-header" href="tel:4867095">
                    {" "}
                    0220 486-7095
                  </a>
                </p>
              </div>
            </div>
            <div className="col col-2">
              <div className="horarios">
                <p>Lunes, martes y jueves de 9 a 17 hs.</p>
                <p>Miércoles y viernes de 9 a 14 hs.</p>
                {/*  <button
                  className="details-col-2"
                  onClick={() => handleDialogOpen(POPUPS.descargas)}
                >
                  Descargas
                </button> */}
                {/*  <button
                  className="details-col-2"
                  onClick={() => handleDialogOpen(POPUPS.formasPago)}
                >
                  Formas de pago
                </button> */}
              </div>
            </div>

            {/*  <div className="col col-2">
              <div>
                <Link className="contact-foot" to="/contacto">
                  Contacto
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      <div className="under-footer">
        <div className="wrapper">
          <p>Todos los derechos reservados @ {new Date().getFullYear()}</p>
          <a href="https://athom.com.ar/" target="_blank">
            <img src={Logodev} alt="Logo de Athom" />
          </a>
        </div>
      </div>
      <button
        className="whatsapp-opener"
        onClick={() => setWhatsappPopupOpen(true)}
      >
        <i className="fab fa-whatsapp"></i>
        <span>Chateá con nosotros!</span>
      </button>

      <div
        className={`whatsapp-container ${classnameDefaults([
          whatsappPopupOpen,
          "active",
        ])}`}
      >
        <div className="top">
          <i
            className="fas fa-times"
            onClick={() => setWhatsappPopupOpen(false)}
          ></i>
          <p>
            ¡Hola! Hacé click en nuestra marca y te responderemos lo antes
            posible
          </p>
        </div>
        <a
          className="bottom"
          href="https://api.whatsapp.com/send?phone=5491163955293"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-whatsapp"></i>
          <div>
            <span>Estamos para ayudarte</span>
            <p>servicepro.com.ar</p>
          </div>
        </a>
      </div>
    </>
  );
};

export default Footer;
