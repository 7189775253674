import PageLayout from "components/common/PageLayout";
import Header from "components/Header";
import Footer from "components/Footer";
import "aos/dist/aos.css";
import ENDPOINTS from "constants/endpoints";
import useFetch from "hooks/useFetch";
import StoreCard from "components/common/StoreCard/StoreCard";

import AliceCarousel from "react-alice-carousel";
import background from "assets/images/background.mp4";
import responsive from "assets/images/responsive-video.mp4";
import "react-alice-carousel/lib/alice-carousel.css";

import FeaturedHome1 from "assets/images/home-featured-2/1.jpeg";
import FeaturedHome2 from "assets/images/home-featured-2/2.jpeg";

import FeaturedHome3 from "assets/images/home-featured/1.jpg";
import FeaturedHome4 from "assets/images/home-featured/2.jpg";
import FeaturedHome5 from "assets/images/home-featured/3.jpg";

import imagen1 from "assets/images/1.jpeg";
import imagen2 from "assets/images/2.jpeg";
import imagen3 from "assets/images/3.jpeg";
import pdf1 from "assets/images/impacto.pdf";
import pdf2 from "assets/images/rev-control.pdf";
import pdf3 from "assets/images/premium.pdf";

import icon1 from "assets/images/icons/1.png";
import icon2 from "assets/images/icons/2.png";
import icon3 from "assets/images/icons/3.png";

import { routes } from "routing/routes";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Aos from "aos";

import HomeSlider1 from "assets/images/home-slider/1.jpg";
import HomeSlider2 from "assets/images/home-slider/2.jpg";
import HomeSlider3 from "assets/images/home-slider/3.jpg";
import HomeSlider4 from "assets/images/home-slider/4.jpg";
import { useTitle } from "react-use";
import HomeSlider1responsive from "assets/images/home-slider/2responsive.jpeg";
import HomeSlider2responsive from "assets/images/home-slider/2responsive.jpeg";
import HomeSlider3responsive from "assets/images/home-slider/3responsive.jpeg";
import HomeSlider4responsive from "assets/images/home-slider/4responsive.jpeg";

import CarrouselBrands from "../CarrouselBrands";

import Typewriter from "typewriter-effect";
import { useState } from "react";
import GennoItem from "components/Genno";

const Home = ({ authToken }) => {
  const [error, loading, done, products] = useFetch(
    {
      method: "get",
      url: ENDPOINTS.getFeaturedProductsHome(),
      params: { token: authToken },
    },
    "Error al cargar los productos, actualizar la pagina para reintentar."
  );

  useTitle(process.env.REACT_APP_PAGE_TITLE);

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  const handleDragStart = (e) => e.preventDefault();

  /*
  
  1- onResized: () => undefined; PRIMERO LLAMAR UNA FUNCION DENTRO DEL EVENTO. DENTRO DE LA FUNCION : IF WINDOW.INNERWITH < 600 POR EJ
  2- LOS ITEMS TIENEN QUE ESTAR METIDOS DENTRO DE UN USE STATE.
  */

  const itemsGrandes = [
    <div
      style={{ backgroundImage: `url(${HomeSlider1})` }}
      className="home-slide"
    >
      <div className="wrapper">
        {/* <p className="p1">Winter ♥️♥️♥️.</p> */}
      </div>
    </div>,
    <div
      style={{ backgroundImage: `url(${HomeSlider2})` }}
      className="home-slide"
    >
      <div className="wrapper">
        {/* <p className="p1">
          Te acompañamos en el outfit de tu bebe, desde prematuro hasta los 5
          años.
        </p> */}
      </div>
    </div>,
    <div
      style={{ backgroundImage: `url(${HomeSlider3})` }}
      className="home-slide"
    >
      <div className="wrapper">
        {/*  <p className="p1">Piensa, Sueña, Cree y Atrévete.</p> */}
      </div>
    </div>,
    <div
      style={{ backgroundImage: `url(${HomeSlider4})` }}
      className="home-slide"
    >
      <div className="wrapper">
        {/*  <p className="p1">Estate atengo a las promos lanzamiento</p> */}
      </div>
    </div>,
  ];

  const itemsChicos = [
    <div
      style={{ backgroundImage: `url(${HomeSlider1responsive})` }}
      className="home-slide"
    >
      <div className="wrapper">
        {/* <p className="p1">Winter ♥️♥️♥️.</p> */}
      </div>
    </div>,
    <div
      style={{ backgroundImage: `url(${HomeSlider2responsive})` }}
      className="home-slide"
    >
      <div className="wrapper">
        {/* <p className="p1">
          Te acompañamos en el outfit de tu bebe, desde prematuro hasta los 5
          años.
        </p> */}
      </div>
    </div>,
    <div
      style={{ backgroundImage: `url(${HomeSlider3responsive})` }}
      className="home-slide"
    >
      <div className="wrapper">
        {/*  <p className="p1">Piensa, Sueña, Cree y Atrévete.</p> */}
      </div>
    </div>,
    <div
      style={{ backgroundImage: `url(${HomeSlider4responsive})` }}
      className="home-slide"
    >
      <div className="wrapper">
        {/*  <p className="p1">Estate atengo a las promos lanzamiento</p> */}
      </div>
    </div>,
  ];

  const [items, setItems] = useState(
    window.innerWidth < 800 ? itemsChicos : itemsGrandes
  );

  const handleSliderResized = () => {
    if (window.innerWidth < 800) {
      setItems(itemsChicos);
    } else {
      setItems(itemsGrandes);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <PageLayout>
        <Header />

        <AliceCarousel
          autoPlay
          autoPlayInterval={10000}
          infinite
          disableButtonsControls
          mouseTracking
          items={items}
          onResized={handleSliderResized}
        ></AliceCarousel>
        {/*    <div className="principal-container">
          <div className="text">
            <Typewriter
              options={{
                strings: [
                  "Somos importadores de Nice Brasil",
                  "Reseller Dahua en Argentina",
                  "Laboratorio Electrónico para Grandes Industrias",
                ],
                autoStart: true,
                loop: true,
                skipAddStyles: true,
                wrapperClassName: "texts-spans",
                cursorClassName: "cursor-text",
                delay: 30,
                deleteSpeed: 10,
              }}
            />
          </div>
          <video className="background-video" autoPlay loop muted playsInline>
            <source src={background} type="video/mp4" />
          </video>

          <video className="video-responsive" autoPlay loop muted playsInline>
            <source src={responsive} type="video/mp4" />
          </video>

          <div className="mask"></div>
        </div> */}

        <div className="home-featured">
          <div className="wrapper">
            <div className="cols">
              <Link to={routes.nice.path} className="col">
                <img src={FeaturedHome3} alt="" />
              </Link>
              <Link to={routes.dahua.path} className="col">
                <img src={FeaturedHome4} alt="" />
              </Link>
              {/*  <Link
                to="tienda/bebe-prematuro-a-24-meses/shop-all"
                className="col"
              >
                <img src={FeaturedHome5} alt="" />
              </Link> */}
            </div>
          </div>
        </div>

        <section className="home-section showcase">
          <div className="wrapper">
            <div className="genno-cols">
              <div className="genno-col">
                <h4>Impacto</h4>
                <div className="image-slider">
                  <img src={imagen1} />
                </div>

                <div>
                  <button onClick={handleClick} className="tech-data">
                    {" "}
                    Datos Técnicos
                  </button>
                  <div className={`tecnic-data ${open ? "open" : ""}`}>
                    <ul>
                      DATOS TECNICOS
                      <li> Tensión de choque: 8.000V / 10.000V / 12.000V</li>
                      <li> Energía emitida: 0,6 joules </li>
                      <li>
                        {" "}
                        Tamaño máximo de la cerca: 3.100 metros lineales.
                      </li>
                      <li> Forma de accionamiento: por botón en el panel.</li>
                      <li>Índice de protección IPX4</li>
                      <li>Salida de LED</li>
                      <li>
                        {" "}
                        Entrada para dispositivo externo (teclado, receptor,
                        botoera, etc)
                      </li>
                      <li>Ajuste de los pulsos fallidos</li>
                      <li>Shutdown de sirena programable</li>
                      <li>Shutdown de choque programable</li>
                      <li> Tiempo de sirena ajustable</li>
                      <li> Beep de sirena programable</li>
                      <li>
                        {" "}
                        "Permite integrar el control vía smatphone cuando
                        interconectado con alarma de la línea Genno Cloud con
                        GPRS e Ethernet/Wifi"
                      </li>
                    </ul>
                  </div>
                </div>

                <a className="dlm" target="_blank" href={pdf1}>
                  Descargá el manual
                </a>

                <a
                  href="https://api.whatsapp.com/send?phone=5491163955293"
                  target="_blank"
                >
                  Consultar precio y stock
                </a>
              </div>

              <div className="genno-col">
                <h4>Revolution Control</h4>
                <div className="image-slider">
                  <img src={imagen2} />
                </div>

                <div>
                  <button onClick={handleClick} className="tech-data">
                    {" "}
                    Datos Técnicos
                  </button>
                  <div className={`tecnic-data ${open ? "open" : ""}`}>
                    <ul>
                      DATOS TECNICOS
                      <li> Tensión de choque: 8.000V / 10.000V / 12.000V</li>
                      <li> Energía emitida: 0,6 joules </li>
                      <li>
                        {" "}
                        Tamaño máximo de la cerca: 3.100 metros lineales.
                      </li>
                      <li> Forma de accionamiento: control remoto</li>
                      <li>2 sectores mixtos e independientes</li>
                      <li>Función pánico por control</li>
                      <li> Índice de protección IPX4</li>
                      <li>Salida de LED</li>
                      <li>
                        Entrada para dispositivo externo (teclado, receptor,
                        bota, etc)
                      </li>
                      <li>Ajuste de los pulsos fallidos</li>
                      <li>Shutdown de sirena programable</li>
                      <li>Shutdown de choque programable</li>
                      <li> Tiempo de sirena ajustable</li>
                      <li> Beep de sirena programable</li>
                      <li>
                        {" "}
                        "Permite integrar el control vía smatphone cuando
                        interconectado con alarma de la línea Genno Cloud con
                        GPRS e Ethernet/Wifi"
                      </li>
                    </ul>
                  </div>
                </div>

                <a className="dlm" target="_blank" href={pdf2}>
                  Descargá el manual
                </a>

                <a
                  href="https://api.whatsapp.com/send?phone=5491163955293"
                  target="_blank"
                >
                  Consultar precio y stock
                </a>
              </div>

              <div className="genno-col">
                <h4>Shock Premium</h4>
                <div className="image-slider">
                  <img src={imagen3} />
                </div>

                <div>
                  <button onClick={handleClick} className="tech-data">
                    {" "}
                    Datos Técnicos
                  </button>
                  <div className={`tecnic-data ${open ? "open" : ""}`}>
                    <ul>
                      DATOS TECNICOS
                      <li> Tensión de choque: 18.000V / 20.000V / 22.000V</li>
                      <li> Energía emitida: 2,5 joules </li>
                      <li>
                        {" "}
                        Tamaño máximo de la cerca: 5.000 metros lineales.
                      </li>
                      <li> Forma de accionamiento: control remoto.</li>
                      <li>2 sectores mixtos e independientes</li>
                      <li>Función pánico por control</li>
                      <li>
                        Zonas configurables (instantánea, inteligente o
                        temporizado)
                      </li>
                      <li>Índice de protección IPX4</li>
                      <li>Salida de LED</li>
                      <li>
                        {" "}
                        Entrada para dispositivo externo (teclado, receptor,
                        botoera, etc)
                      </li>
                      <li>Ajuste de los pulsos fallidos</li>
                      <li>Shutdown de sirena programable</li>
                      <li>Shutdown de choque programable</li>
                      <li> Tiempo de sirena ajustable</li>
                      <li> Beep de sirena programable</li>
                      <li>
                        {" "}
                        "Permite integrar el control vía smatphone cuando
                        interconectado con alarma de la línea Genno Cloud con
                        GPRS e Ethernet/Wifi"
                      </li>
                    </ul>
                  </div>
                </div>

                <a className="dlm" target="_blank" href={pdf3}>
                  Descargá el manual
                </a>

                <a
                  href="https://api.whatsapp.com/send?phone=5491163955293"
                  target="_blank"
                >
                  Consultar precio y stock
                </a>
              </div>
            </div>

            {/*      {loading && (
              <div className="showcase-loading">
                <i className="fas fa-spinner fa-pulse"></i>
                <span>Cargando</span>
              </div>
            )}

            {!!error && <p>{error}</p>}
            {done && products.length === 0 && (
              <p>No hay productos destacados aún.</p>
            )}

            <div className="product-grid">
              {products.map((product, index) => (
                <StoreCard
                  index={index}
                  imagePath={process.env.REACT_APP_PRODUCT_THUMBNAIL_IMAGE_PATH}
                  key={product.id}
                  image={product.image.filename}
                  title={product.title}
                  isProduct={true}
                  product={product}
                />
              ))}
            </div> */}
          </div>
        </section>

        <div className="home-featured-2">
          <div className="wrapper">
            <div className="cols">
              <Link to="#" className="col">
                <img src={FeaturedHome1} className="" alt="" />
              </Link>
              <Link to="#" className="col">
                <img src={FeaturedHome2} className="" alt="" />
              </Link>
              {/*  <Link to="tienda/e-gift/e-gift" className="col">
                <img
                  src={FeaturedHome1}
                  data-aos="flip-right"
                  data-aos-offset="100"
                  className="aos-init aos-animate"
                  alt=""
                />
              </Link>
              <Link to="tienda/nio-de-2-a-5-aos" className="col">
                <img
                  src={FeaturedHome2}
                  data-aos="flip-left"
                  data-aos-offset="100"
                  className="aos-init aos-animate"
                  alt=""
                />
              </Link> */}
            </div>
          </div>
        </div>

        {/*    <Link className="showroom" to={routes.registro.path} target="_blank">
          <div className="wrapper">
            <p>Compras comunitarias</p>
          </div>
        </Link> */}

        {/*  <div className="services-container wrapper">
          <div className="services-details">
            <img src={icon1} />
            <h1>Elegí los productos que vas a comprar</h1>
            <p>Si queres más de uno, sumalos a tu carrito</p>
          </div>
          <div className="services-details">
            <img src={icon2} />
            <h1>Paga con el medio de pago que quieras</h1>
            <p>Utilizamos la tecnología de Mercado Pago</p>
          </div>
          <div className="services-details">
            <img src={icon3} />
            <h1>Recibí el producto que esperás</h1>
            <p>Elegí la forma de entrega que prefieras y listo!</p>
          </div>
        </div> */}

        <div className="brands-container">
          <CarrouselBrands />
        </div>
      </PageLayout>
      <Footer />
    </>
  );
};

export default Home;
