import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { classnameDefaults } from "utils/common";

const PendingPayment = ({
  paymentLink,
  cancelPurchase,
  cancelPurchaseRequestData,
}) => {
  const history = useHistory();

  const cancelHandler = async () => {
    if (cancelPurchaseRequestData.loading) return;

    const result = await Swal.fire({
      icon: "warning",
      title: "¿Estás seguro que deseas cancelar el pedido?",
      text: "Esta acción no puede ser revertida",
      heightAuto: false,
      showCancelButton: true,
      confirmButtonText: "Sí, cancelar pedido",
      confirmButtonColor: "#d33",
      cancelButtonText: "No cancelar",
    });

    if (result.isConfirmed) cancelPurchase(history);
  };

  return paymentLink ? (
    <>
      {/*  <h1>
        <i className='fas fa-exclamation-circle'></i> El pago no se ha realizado
        correctamente :(
      </h1>
      <p>
        Su pago ha sido rechazado o no pudo ser completado, por favor, intente
        nuevamente haciendo click en el link de abajo:
      </p>
      <a href={paymentLink}>Pagar con MercadoPago</a> */}

      <br />
      <br />
      <br />

      <p>
        Si desea cancelar la compra,{" "}
        <button
          className={`cancel-purchase-button ${classnameDefaults([
            cancelPurchaseRequestData.loading,
            "disabled",
          ])}`}
          onClick={cancelHandler}
        >
          {cancelPurchaseRequestData.loading ? (
            <>
              <span>cancelando</span>{" "}
              <i className="fas fa-spinner fa-pulse"></i>
            </>
          ) : (
            <span>haga click aquí</span>
          )}
        </button>
        .
      </p>
    </>
  ) : (
    <h1>
      <i className="fas fa-exclamation-circle"></i> Se ha producido un error con
      su compra, por favor comuníquese con nosotros :(
    </h1>
  );
};

export default PendingPayment;
