import React, { useState } from "react";
import { formatPrice, getDiscount, classnameDefaults } from "utils/common";

/*
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

*/

/* import Carousel from "react-carousel"; */

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { useEffect } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

const Product = ({ product, user, addToCart, addToCartRequestData }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const history = useHistory();
  const amountInputRef = useRef();
  const talleRef = useRef();

  const getProductText = (description) => {
    const splitted = product.description.split("\n");

    if (splitted.length <= 1) return [<p>{description}</p>];

    let acc = 0;
    const content = [];

    for (let i = splitted.length - 1; i > 0; i--) {
      const part = splitted[i];

      if (part !== "") {
        content.push(<p style={{ marginBottom: `${20 * acc}px` }}>{part}</p>);
        acc = 0;
      } else {
        acc++;
      }
    }

    return content.reverse();
  };

  const priceParts = formatPrice(product.price);
  const priceDiscountParts = formatPrice(product.price_discount);

  const addToCartHandler = () => {
    const amount = parseInt(amountInputRef.current.value);

    addToCart(product, amount, history);
  };

  const changeSlideHandler = (index) => {
    setCurrentSlide(index);
  };

  const prevSlideHandler = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? product.images.length - 1 : prev - 1
    );
  };

  const nextSlideHandler = () => {
    setCurrentSlide((prev) =>
      prev === product.images.length - 1 ? 0 : prev + 1
    );
  };

  const keydownHandler = (e) => {
    if (!lightboxOpen) return;

    switch (e.which) {
      case 37:
        prevSlideHandler();
        break;
      case 39:
        nextSlideHandler();
        break;
      case 27:
        setLightboxOpen(false);
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keydownHandler);

    return () => {
      window.removeEventListener("keydown", keydownHandler);
    };
  }, [lightboxOpen]);

  return (
    <div className="product-set">
      <div className="slider-set">
        <div className="product-slider">
          <Carousel
            value={currentSlide}
            onChange={setCurrentSlide}
            plugins={["arrows"]}
          >
            {product.images.map((item, index) => (
              <div
                key={item.filename}
                className={`slide-wrap slide-number-${index}`}
                onClick={() => setLightboxOpen(true)}
              >
                <div className="item">
                  <img
                    src={`${process.env.REACT_APP_PRODUCT_IMAGE_PATH}/${item.filename}`}
                    data-slide={index}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="product-thumbnail">
          {product.images.map((item, index) => {
            return index < 5 ? (
              <button
                key={item.filename}
                className="thumbnail"
                onClick={() => changeSlideHandler(index)}
              >
                <div className="bg-fill"></div>
                <img
                  src={`${process.env.REACT_APP_PRODUCT_THUMBNAIL_IMAGE_PATH}/${item.filename}`}
                />
              </button>
            ) : (
              <button key={item.filename} className="thumbnail">
                <div className="bg-fill"></div>
                <div className="thumbs-left">+{product.images.length - 5}</div>
                <img
                  src={`${process.env.REACT_APP_PRODUCT_THUMBNAIL_IMAGE_PATH}/${item.filename}`}
                />
              </button>
            );
          })}
        </div>
      </div>

      <div className="product-info">
        <h2>{product.title}</h2>
        {product.on_discount == "1" ? (
          <>
            <p className="price">
              <span className="price-after">
                {/*   <span>{priceDiscountParts[0]}</span>
                <span className="product-decimal">{priceDiscountParts[1]}</span> */}
              </span>
              <span className="price-before">
                {/*    <span>{priceParts[0]}</span>
                <span className="product-decimal">{priceParts[1]}</span> */}
              </span>
            </p>
            {/*   <p className="discount">
              <span>
                {getDiscount(product.price, product.price_discount)}% de
                descuento
              </span>
            </p>
            <p className="discount-label">
              Precio promocional solo válido para las compras realizadas en
              nuestra tienda online.
            </p> */}
          </>
        ) : (
          <p className="price no-discount">
            {/*   <span>{priceParts[0]}</span>
            <span className="product-decimal">{priceParts[1]}</span> */}
          </p>
        )}

        {/*   <p class="hay-stock">
          <i class="fas fa-smile"></i> Hay stock
        </p> */}

        <label>
          Cantidad{" "}
          {user ? <span>(Mínimo {product.minimal_amount} productos)</span> : ""}
        </label>
        <input
          type="number"
          name="cantidad"
          min={1}
          max="100"
          defaultValue={1}
          ref={amountInputRef}
        />
        {/* 
     <label>Talle</label>
        <select ref={talleRef}>
          {product.talles.map((item) => (
            <option value={item.id}>{item.value}</option>
          ))}
        </select> 
 */}
        <div>
          <button
            onClick={addToCartHandler}
            className={`button add-to-cart ${classnameDefaults([
              addToCartRequestData.loading,
              "disabled",
            ])}`}
          >
            {addToCartRequestData.loading ? (
              <>
                <i className="fas fa-pulse fa-spinner"></i>
                <span>Añadiendo al carrito</span>
              </>
            ) : (
              <>
                <i className="fas fa-cart-plus"></i>
                <span>Añadir al carrito</span>
              </>
            )}
          </button>
        </div>
        <div className="description-block">
          <label>Descripción:</label>
          <br />
          <div className="description">
            {getProductText(product.description).map((item, index) => (
              <React.Fragment key={index}>{item}</React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className={`lightbox ${classnameDefaults([lightboxOpen, "on"])}`}>
        <div
          className="lightbox-backdrop"
          onClick={() => setLightboxOpen(false)}
        ></div>
        <div className="metadata">
          <span className="current-slide">{currentSlide + 1}</span>
          <span className="middle-bar">/</span>
          {product.images.length}
        </div>
        <button
          className="close-lightbox"
          onClick={() => setLightboxOpen(false)}
        >
          <i className="fas fa-times"></i>
        </button>
        <button className="prev-slide" onClick={prevSlideHandler}>
          <i className="fas fa-chevron-left"></i>
        </button>
        <button className="next-slide" onClick={nextSlideHandler}>
          <i className="fas fa-chevron-right"></i>
        </button>

        <img
          src={`${process.env.REACT_APP_PRODUCT_IMAGE_PATH}/${product.images[currentSlide].filename}`}
        />
      </div>
    </div>
  );
};

export default Product;
