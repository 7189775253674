import Footer from "./Footer";
import Header from "./Header";
import PageLayout from "./common/PageLayout";
import dahua from "../assets/images/dahua.jpeg";
import dahuaresponsive from "../assets/images/dahua-responsive.jpeg";
import ScrollToTop from "./ScrollToTop";

const Dahua = () => {
  return (
    <div>
      <ScrollToTop />
      <PageLayout>
        <Header />
        <img className="nice-img" src={dahua} alt="" />
        <img className="nice-img-responsive" src={dahuaresponsive} alt="" />
        <div className="nice-container wrapper">
          <h1>TECNOLOGÍA E INNOVACIÓN </h1>
          <div className="details-nice-container">
            <p>
              Dahua Technology es un proveedor líder de soluciones en la
              industria global de videovigilancia. Con alrededor de 13.000
              empleados en todo el mundo, las soluciones, productos y servicios
              de Dahua se utilizan en más de 180 países y regiones. Dahua tiene
              35 filiales a nivel mundial que cubren Asia, América, Europa,
              Medio Oriente, Oceanía y África. En 2001, se convirtió en la
              primera compañía en China en lanzar una grabadora de video digital
              embebida en tiempo real de 8 canales. Desde entonces, la compañía
              ha seguido invirtiendo en la construcción de capacidades sólidas
              de I + D para nuevas tecnologías e innovación.
            </p>

            <p>
              Productos El portafolio de productos de la compañía incluye:
              equipo y software de videovigilancia, hardware y software para
              control de accesos, hardware para detección de intrusos y gestión
              de sistemas de seguridad para edificios inteligentes. Los
              productos Dahua se han implementado extensamente en diversas
              industrias, tales como bancos, seguridad pública y gobierno,
              energía, retail, telecomunicaciones, edificios inteligentes y
              soluciones de seguridad para transporte.
            </p>
            <p className="certif"> Certificaciones</p>

            <ul>
              <li>
                Certificación de la protección de la privacidad de datos GDPR
                por TÜV Rheinland
              </li>
              <li>CMMI Maturity Level 5(Optimizing) certificate</li>
              <li> ULC certificate</li>
              <li> FCC certificate</li>
              <li>CE Certificate</li>
              <li> CCC certificate</li>
              <li>ISO14000 WIT assessment</li>
              <li>OHSMS18000 WIT assessment</li>
              <li>ISO9000 WIT assessment</li>
              <li> ISO/IEC 27001: 2013 certificate</li>
            </ul>
          </div>
        </div>

        <Footer />
      </PageLayout>
    </div>
  );
};

export default Dahua;
