import { createSelector } from "reselect";

export const getCartState = (state) => state.cart;

export const getCart = createSelector([getCartState], ({ cart }) => cart);
export const getCartItemsAmount = createSelector([getCartState], ({ cart }) =>
  cart.reduce((total, item) => total + parseInt(item.amount), 0)
);

export const getCartItemsValue = createSelector([getCartState], ({ cart }) =>
  cart.reduce(
    (total, item) => total + parseInt(item.price) * parseInt(item.amount),
    0
  )
);

export const getCartItemsAmountLabels = createSelector(
  [getCartItemsAmount],
  (amount) => {
    const itemString = amount === 1 ? "item" : "items";
    return amount === 0 ? "(0)" : `(${amount} ${itemString})`;
  }
);

export const getAddToCartRequestData = createSelector(
  [getCartState],
  ({ addToCartRequestData }) => addToCartRequestData
);

export const getCheckoutRequestData = createSelector(
  [getCartState],
  ({ checkoutRequestData }) => checkoutRequestData
);

export const getCheckout = createSelector(
  [getCartState],
  ({ checkout }) => checkout
);

export const getChangeAmountCartRequestData = createSelector(
  [getCartState],
  ({ changeAmountCartRequestData }) => changeAmountCartRequestData
);

export const getChangeAmountCartId = createSelector(
  [getCartState],
  ({ changeAmountCartId }) => changeAmountCartId
);

export const getDeleteProductCartRequestData = createSelector(
  [getCartState],
  ({ deleteProductCartRequestData }) => deleteProductCartRequestData
);

export const getDeleteProductCartId = createSelector(
  [getCartState],
  ({ deleteProductCartId }) => deleteProductCartId
);

export const getSubmitPurchaseRequestData = createSelector(
  [getCartState],
  ({ submitPurchaseRequestData }) => submitPurchaseRequestData
);

export const getCancelPurchaseRequestData = createSelector(
  [getCartState],
  ({ cancelPurchaseRequestData }) => cancelPurchaseRequestData
);

export const getPurchaseStatus = createSelector(
  [getCartState],
  ({ purchaseStatus }) => purchaseStatus
);

export const getPurchaseStatusRequestData = createSelector(
  [getCartState],
  ({ purchaseStatusRequestData }) => purchaseStatusRequestData
);

export const getSendMessageRequestData = createSelector(
  [getCartState],
  ({ sendMessageRequestData }) => sendMessageRequestData
);
