import Footer from "./Footer";
import Header from "./Header";
import PageLayout from "./common/PageLayout";
import nice from "../assets/images/nice.jpeg";
import niceresponsive from "../assets/images/nice-responsive.jpeg";
import ScrollToTop from "./ScrollToTop";

const Nice = () => {
  return (
    <div>
      <ScrollToTop />
      <PageLayout>
        <Header />
        <img className="nice-img" src={nice} alt="" />
        <img className="nice-img-responsive" src={niceresponsive} alt="" />
        <div className="nice-container wrapper">
          <h1>TECNOLOGÍA E INNOVACIÓN </h1>
          <div className="details-nice-container">
            <p>
              Peccinin y Genno siempre han invertido en tecnología e innovación,
              pero desde la incorporación de las empresas a The Nice Group.
            </p>

            <p>
              {" "}
              Estos valores se han fortalecido aún más, combinando toda la
              experiencia con soluciones inteligentes y desarrollo de diseños
              que tienen éxito en todo el mundo.
            </p>
            <p>
              Con este apoyo, las dos marcas reformularon constantemente sus
              líneas de productos, utilizando ingeniería propia y diversas
              soluciones mecánicas, electrónicas y de diseño totalmente
              adaptadas al mercado brasileño y latinoamericano.
            </p>
          </div>
        </div>

        <Footer />
      </PageLayout>
    </div>
  );
};

export default Nice;
