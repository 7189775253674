import { Switch, Route } from "react-router-dom";
import { routes } from "routing/routes";
import Home from "components/Home";

import UnloggedRoute from "routing/route-types/UnloggedRoute";
import IniciarSesion from "components/IniciarSesion";
import Store from "components/Store";
import Checkout from "components/Checkout";
import CompraFinalizada from "components/CompraFinalizada";
import Nosotros from "components/Nosotros/Nosotros";
import Contacto from "components/Contacto";
import Register from "components/Register";
import RecoverPassword from "components/RecoverPassword";
import NewPassword from "components/NewPassword";
import PurchaseStatus from "components/PurchaseStatus";
import Search from "components/Search";
import SoporteTecnico from "components/SoporteTecnico/SoporteTecnico";
import Genno from "components/Genno";
import Nice from "components/Nice";
import Dahua from "components/Dahua";

const Routing = () => {
  return (
    <>
      <Switch>
        <Route exact path={routes.base.path} component={Home} />
        <Route exact path={routes.soporte.path} component={SoporteTecnico} />
        <UnloggedRoute
          exact
          path={routes.login.path}
          component={IniciarSesion}
        />
        <UnloggedRoute exact path={routes.registro.path} component={Register} />
        <UnloggedRoute
          exact
          path={routes.recoverPassword.path}
          component={RecoverPassword}
        />
        <UnloggedRoute
          exact
          path={routes.newPassword.path}
          component={NewPassword}
        />

        <Route path={routes.tienda.base.path} component={Store} />
        <Route path={routes.checkout.path} component={Checkout} />

        <Route path={routes.purchaseDone.path} component={CompraFinalizada} />
        <Route path={routes.purchaseStatus.path} component={PurchaseStatus} />

        <Route path={routes.nosotros.path} component={Nosotros} />
        <Route path={routes.contacto.path} component={Contacto} />
        <Route path={routes.search.path} component={Search} />
        <Route path={routes.genno.path} component={Genno} />
        <Route path={routes.nice.path} component={Nice} />
        <Route path={routes.dahua.path} component={Dahua} />
      </Switch>
    </>
  );
};

export default Routing;
