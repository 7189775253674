import {
  classnameDefaults,
  formatPrice,
  checkBooleanFields,
} from "utils/common";

const CheckoutRow = ({
  item,
  changeAmountCartRequestData,
  changeAmountCart,
  changeAmountCartId,
  deleteProductCartRequestData,
  deleteProductCartId,
  deleteProductCart,
  user,
}) => {
  const productMinimalAmount = user ? parseInt(item.minimal_amount) : 1;
  const isMinimal = parseInt(item.amount) === productMinimalAmount;
  const isMax = parseInt(item.amount) === 100;

  const priceParts = formatPrice(item.price);
  const totalPriceParts = formatPrice(item.price * item.amount);

  const requestData = checkBooleanFields([
    changeAmountCartRequestData,
    deleteProductCartRequestData,
  ]);

  const addOneToCart = () => {
    if (isMax || requestData.loading) return;
    changeAmountCart(item.id, "up");
  };

  const removeOneFromCart = () => {
    if (isMinimal || requestData.loading) return;
    changeAmountCart(item.id, "down");
  };

  const deleteFromCart = () => {
    if (requestData.loading) return;
    deleteProductCart(item.id);
  };

  return (
    <div className="row row-normal">
      <div className="col col-title">
        <span>{item.title}</span>
      </div>
      <div className="col col-amount">
        <i
          onClick={removeOneFromCart}
          className={`fas fa-chevron-circle-down ${classnameDefaults([
            isMinimal,
            "disabled",
            !isMinimal,
            "change-amount",
          ])}`}
        ></i>
        <span>
          {changeAmountCartRequestData.loading &&
          changeAmountCartId === item.id ? (
            <i className="fas fa-spinner fa-pulse"></i>
          ) : (
            item.amount
          )}
        </span>
        <i
          onClick={addOneToCart}
          className={`fas fa-chevron-circle-up ${classnameDefaults([
            isMax,
            "disabled",
            !isMax,
            "change-amount",
          ])}`}
        ></i>
      </div>
      <div className="col">
        <b>
          {/* <span>{priceParts[0]}</span> */}
          {/*  <span className="product-decimal">{priceParts[1]}</span> */}
        </b>
      </div>
      <div className="col">
        <b>
          {/* <span>{totalPriceParts[0]}</span> */}
          {/* <span className="product-decimal">{totalPriceParts[1]}</span> */}
        </b>
      </div>
      <div className="col col-delete">
        {deleteProductCartRequestData && deleteProductCartId === item.id ? (
          <i className="fas fa-spinner fa-pulse"></i>
        ) : (
          <>
            <button
              className="checkout-row-delete-button"
              onClick={deleteFromCart}
            >
              <span>Quitar</span>
              <i className="fas fa-trash delete-product"></i>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutRow;
