import React from 'react';
import { Link } from 'react-router-dom';
import { classnameDefaults } from 'utils/common';

const Breadcrumb = ({ data }) => {
  return (
    <h2 className='breadcrumb'>
      {data.map((item, index) => (
        <React.Fragment key={index}>
          <Link
            className={classnameDefaults([index === data.length - 1, 'on'])}
            to={item.link}
          >
            {item.label}
          </Link>
          {index !== data.length - 1 && <i className='fas fa-angle-right'></i>}
        </React.Fragment>
      ))}
    </h2>
  );
};

export default Breadcrumb;
